body {
  font: 62.5% 'Roboto', 'Open Sans', Verdana, Arial, Helvetica, sans-serif;
  font-size: 100%;
  position: relative;
  color: #3C3C3C;
  margin-top: 100px;
}

h1 {
  font-size: 2.35em;
  font-weight: 300;
}

h2 {
  font-size: 1.6em;
  font-weight: 300;
}

.cip {
  margin-left: 15px;
  font-weight: 700;
  font-size: 24px;
}

.nav-logo {
  height: 75px;
  background-color: #ffffff;
  padding: 3px 20px;
}

.navbar {
  padding: 0px;
  height: 75px;
  background-color: #3C3C3C;
  border-left: 6px solid #BE1E00;

  text-decoration: none;
  text-transform: uppercase;
  font-weight: 300;
  font-size: 0.88em;
  line-height: 55px;

  box-shadow: 0 2px 4px 0 rgb(105 117 130 / 16%), 0 2px 4px 0 rgb(105 117 130 / 23%);
}

.navbar-brand {
  color: #ffffff; 
}

.navbar-brand:hover {
  color: #ffffff; 
}

.nav-item {
  margin-right: 30px;
}

.nav-link {
  color: #ffffff; 
}


.nav-link:hover {
  color: #ffffff; 
  font-weight: 500;
}

.navbar-brand {
  padding: 0px;
}

.container {
  background-color: #ffffff;
}

a {
  text-decoration: none;
}


element.style {
}
.footer {
    background-color: #3c3c3c;
    min-height: 35px;
    padding: 10px;
    font-size: .7em;
    color: #eff3f5;
}